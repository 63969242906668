<template>
  <div class="tag tag-popular" v-if="card.name === 'Pro'">
    Most popular
  </div>
  <div class="tag tag-save" v-if="card.name === 'Standard'">
    Best price
  </div>
  <div class="card-header">
    <!-- card标题 -->
    <p class="card-title">{{ card.title }}</p>
    <!-- 价格 -->

    <div class="card-price">
      <!-- 模拟价格的骨架 -->
      <el-skeleton style="width: 80px; height: 60px" :loading="isLoading" animated>
        <template #template>
          <el-skeleton-item variant="text" style="width: 100%; height: 100%" />
        </template>
        <template #default>
          <span v-if="card.couponCode" style="height: 60px;" :style="{color: active ? '#6741FF' : '' }">
            {{ card.monthlyDiscount }}
          </span>
          <span v-else style="height: 60px;" :style="{color: active ? '#6741FF' : '' }"> {{ card.monthlyPrice }}
          </span>
        </template>
      </el-skeleton>
    </div>
    <p class="text-titleColor price-description" v-if="card.couponCode">
      {{ `Per user${card.cycleType === "year" ? " / mon" : ""} for first ${card.cycleType}` }}
    </p>
    <p class="text-titleColor price-description" v-else>
      Per user / month
    </p>
    <div class="payment-description-container">
      <p class="payment-description" v-if="card.couponCode">
        {{ `Then ${card.monthlyPrice} / mon starting next ${card.cycleType}` }}
      </p>
      <p class="payment-description">
        {{ card.paymentDescription }}
      </p>
    </div>

    <!-- card plan描述 -->
    <p class="card-plan-description" v-if="card.couponCode">
      <span style="color: #6741FF">{{ `With code: ${card.couponCode}` }}</span>
    </p>
    <p v-else class="card-plan-description">
      {{ card.planDescription }}
    </p>
  </div>
  <!-- 卡片按钮 -->
  <div :class="`card-button relative ${isLoading || btnDisabled ? 'pointer-events-none cursor-default' : ''
    }`">
    <el-icon :style="loadingStyle" v-if="btnDisabled">
      <Loading />
    </el-icon>
    <button v-if="canCancel" class="current-button" :disabled="btnDisabled" @click="handleCancel(card)">
      {{ initButtonType(card) }}
    </button>
    <button v-else class="upgrade-button" :class="{'plain-button': card.packageCode == 0}" @click="enquiryOperType(card)" :disabled="btnDisabled">
      {{ initButtonType(card) }}
    </button>
  </div>
  <!-- 卡片主体 -->
  <div class="card-body">
    <div class="free-body">
      <div class="tool-wrapper">
        <ul class="free-tool-box">
          <li v-for="(tool, index) in card.priorityList" :key="index" class="flex items-start">
            <div class="icon-box">
              <img :src="icon_selected" v-once/>
              </div> <span> {{ tool }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <QuestionnaireModal 
    v-if="questionnaireVisible"
    v-model="questionnaireVisible" 
    @close="questionnaireVisible = false" 
    @submit="submit"
  />
  <GiftcardModal 
    v-model="giftcardVisible" 
    @close="cancelCallBack"
  />
</template>

<script setup lang="tsx">
import { useMessage } from "@/utils";
import { debounce } from "@/utils/debounce";
import { isLogin, getToken } from "@/utils/auth";
import { getOperType, ltdRefund } from "@/api/premium";
import type { CardItemInfo } from "../../type";
import { useSubscriptionInfo } from "@/store/modules/user";
import { useTrackStore } from "@/store/modules/track";
import icon_selected from '@/assets/images/premium/icon_selected.png'
import { useModalManager } from "@/components/common/custom-modal/instance";
import { modal_banner, Success } from "@/assets/images/premium";
import QuestionnaireModal from "../modal/questionnaire.vue"; 
import GiftcardModal from "../modal/giftcard.vue";
import { usePricingStore } from "../../stores";

const message = useMessage();
const route = useRoute();
const router = useRouter();
const { collectData, getTrackPlanName, track } = useTrackStore();
const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { updateSubscriptionState } = useSubscriptionInfo();
const { currentCycleType } = usePricingStore();

const props = defineProps({
  card: {
    type: Object as PropType<CardItemInfo>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  userOperationMap: {
    type: Object,
    required: true,
  },
  active: Boolean
});

const ltdPackageCode = [300204, 300205, 300304, 300305, 300404, 300405];
const starterCodes = [300401, 300402]
const sortCode = [300201, 300301, 300202, 300302]

const modalManager = useModalManager();
const giftcardVisible = ref(false);
const questionnaireVisible = ref(false);
const btnDisabled = ref(false);
// 按钮loading时Spin图标样式
const loadingStyle = reactive({
  position: "absolute",
  left: "50px",
  top: "50%",
  color: "#fff",
  transform: "translate(-50%,-50%)",
});
const canCancel = computed(() => {
  const state = subscriptionState.value;
  return state.packageCode === props.card.packageCode && !state.cancelSubAtPeriodEndBoolean
})

const initButtonType = (card: CardItemInfo) => {
  const state = subscriptionState.value;
  const indexCurrent = sortCode.findIndex(code => code == state.packageCode)
  const indexCard = sortCode.findIndex(code => code == card.packageCode)
  const isStarter = starterCodes.findIndex(code => code == state.packageCode)

  if(state.packageCode == 300401 && card.packageCode == 300201) {
     // stater套餐免费升级Standard
     return "Free upgrade";
  }

   if(state.packageCode == 300402 && card.packageCode == 300202) {
     // stater套餐免费升级Standard
     return "Upgrade";
  }

  if(state.packageCode &&  indexCard >=0 &&indexCurrent>=0 &&  indexCard > indexCurrent) {
    // 更高级别的套餐显示Upgrade
     return "Upgrade";
  }

  if (card.packageCode > 0 && ltdPackageCode.includes(state.packageCode)) {
    return "Update Plan";
  }

  if (state.packageCode === card.packageCode) {
    if (state.cancelSubAtPeriodEndBoolean) {
      return "Resume Plan";
    } else {
      return "Cancel My Plan";
    }
  } else if (state.packageCode > 0 && card.packageCode > 0) {
    return "Update Plan";
  } else {
    return card.buttonText;
  }
};

const updateTriggerGA = (card: CardItemInfo) => {
  const state = subscriptionState.value;

  if (state.packageCode === card.packageCode) {
    if (state.cancelSubAtPeriodEndBoolean) {
      gtag("event", "click_resume_subscribe", {
        type: card.cycleType,
        package_code: card.packageCode,
      });
    }
  } else {
    if (state.packageCode == -1) {
      gtag("event", `click_subscribe`, {
        type: card.cycleType,
        package_code: card.packageCode,
      });
      return;
    }
    // update订阅埋点
    gtag("event", `click_update_subscribe`, {
      type: card.cycleType,
      package_code: card.packageCode,
    });
  }
};

// 查询当前操作状态
const enquiryOperType = debounce(async (card: CardItemInfo) => {
  updateTriggerGA(card);
  if (!isLogin.value) {
    router.push({ path: "/signup", query: { redirect: route.fullPath } });
  }
  if (card.name === "Ultra") {
    props.userOperationMap.contactUs();
    return;
  }
  const state = subscriptionState.value;
  
  if (ltdPackageCode.includes(state.packageCode)) {
    props.userOperationMap.ltdTips();
    return;
  }
  const packageCode = card.packageCode;

  if (packageCode > 0) {
    btnDisabled.value = true;
    const cancelOperBoolean = false;
    await getOperType({ cancelOperBoolean, packageCode })
      .then((res) => {
        if (res.code === 102022) {
          props.userOperationMap.yearToMonthTips();
          return;
        } else if (res.code === 0) {
          const {
            currentPeriodPaymentStatus,
            subscriptionOperType,
            boolvideoUpdateSubPopMsg,
          } = res.data;
          if (currentPeriodPaymentStatus === "UNPAID") {
          } else {
            switch (subscriptionOperType) {
              case "NEW_SUBSCRIPTION":
                props.userOperationMap.subscribe(props.card);
                break;
              case "UPGRADE_SUBSCRIPTION":
                collectData("boolvideo_subscribe_upgrade_confirm", {
                  plan_name: getTrackPlanName(props.card.packageCode),
                });
                props.userOperationMap.upgrade(props.card);
                break;
              case "RECOVER_SUBSCRIPTION":
                (async () => {
                  await props.userOperationMap.recover();
                  await updateSubscriptionState();
                })();
                if (
                  window.opener &&
                  window.opener?.location.host === window.location.host
                ) {
                  window.opener.postMessage(
                    { subscribeSuccess: "true", token: getToken() },
                    window.opener.location.origin,
                  );
                }
                break;
              case "DEGRADE_SUBSCRIPTION":
                collectData("boolvideo_subscribe_downgrade_confirm", {
                  plan_name: getTrackPlanName(props.card.packageCode),
                });
                props.userOperationMap.downgrade(
                  props.card,
                  boolvideoUpdateSubPopMsg,
                );
                break;
              default:
                message.error("Oops, this is a error message.");
            }
          }
        }
      })
      .finally(() => {
        btnDisabled.value = false;
      });
  }
}, 500);

const subscribeTriggerGA = (card: CardItemInfo) => {
  const state = subscriptionState.value;
  if (state.packageCode === card.packageCode) {
    if (!state.cancelSubAtPeriodEndBoolean) {
      gtag("event", `click_subscribing`, {
        type: card.cycleType,
        package_code: card.packageCode,
      });
    }
  } else {
    // 初次订阅埋点
    gtag("event", `click_subscribe`, {
      type: card.cycleType,
      package_code: card.packageCode,
    });
  }
};

const handleCancel = (card: CardItemInfo) => {
  collectData("boolvideo_subscribe_cancel", {
    click: "warning_tips",
  });
  track("boolvideo_subscribe_cancel");
  // 打开调查问卷
  questionnaireVisible.value = true;
};

const handleSubscribing = (card: CardItemInfo) => {
  subscribeTriggerGA(card);
  props.userOperationMap.subscribing(card, 1);
};

const submit = async () => {
  if (subscriptionState.value.supportRefundBoolean) {
    await cancelLtd();
    updateSubscriptionState();
  } else {
    giftcardVisible.value = true;
  }
  questionnaireVisible.value = false;
};

const cancelCallBack = async () => {
  questionnaireVisible.value = false;
  giftcardVisible.value = false;
  updateSubscriptionState();
}

const cancelLtd = async () => {
  const res = await ltdRefund();
  if (res.success) {
    refundSuccessModal();
  } else {
    return;
  }
};

const refundSuccessModal = () => {
  modalManager.applyTemplate("info", {
    title: "",
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="w-max flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Refund has been initiated
            </p>
          </div>
        </div>
        <div class="absolute z-10 top-5 right-5">
          <div
            class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={() => modalManager.modal.onClose()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p class="pb-6">
            Your bank may take upto 5-7 business days to credit the refund to
            your account. If you have any issues, please contact{" "}
            <a href="mailto:support@boolvector.com" class="text-primaryColor">
              support@boolvector.com
            </a>
          </p>
        </div>
      </>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    close();
  };
};
</script>

<style lang="scss" scoped>
.tag {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 8px;
  background: #fff500;
  border-radius: 4px;
}

.tag-popular {
  background: #fff500;
  color: #000000;
}

.tag-save {
  background: #6741FF;
  color: #ffffff;
}

.card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
}

.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #060606;
  margin-top: 32px;
}

.card-price {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: normal;
  text-align: center;
  color: #060606;
  margin-top: 12px;
}

.payment-description {
  margin-top: 4px;
  font-size: 16px;
  color: #646a73;

  &::after {
    content: "\200B";
  }
}

.card-plan-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #646a73;
  // height: 57px;
  text-align: center;
  margin-top: 20px;
}

.card-body {
  flex: 1 1;
  margin-top: 36px;
}

.free-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.free-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #060606;
}

.tool-wrapper {
  max-width: 282px;
}

.free-tool-box {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  list-style: disc;
  color: #060606;
  padding-left: 8px;
  word-wrap: break-word;

  li {
    color: #060606;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 220%;
    margin-bottom: 8px;

    .icon-box {
      width: 14px;
      height: 14px;
      border-radius: 4px;
      background: #dbdada;
      display: flex;
      align-items: center;
      margin-right: 6px;
      margin-top: 4px;
    }

    img {
      width: 14px;

    }
  }

  span {
    vertical-align: middle;
  }
}

.payment-description-container {
  flex: 0 0 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// button
.card-button {
  width: 100%;
  height: 48px;
  border-radius: 36px;
  font-size: 16px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0px 24px;
  cursor: pointer;
  user-select: none;
}

.free-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  color: #8f959e;
  border: 1px solid #d0d0d0;
}

.upgrade-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  color: #ffffff;
  transition: all 0.5s;
  background: #6741ff;
  box-sizing: border-box;

  &:hover {
    background: #7856ff;
  }

  &:disabled {
    background: #bbbfc4;
  }
}

.plain-button {
  background: #fff;
  border-radius: 36px;
  border: 1px solid #6741FF;
  color: #6741FF;
  &:hover {
    background: #F8F5FF;
  }
}

.current-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  border: 1px solid #875eff;
  color: #875eff;

  &:disabled {
    background: #bbbfc4;
  }
}

.cancel-button {
  margin-top: 46px;
  color: #646A73;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (min-width: 1340px) {
  .card-button {
    max-width: 255px;
  }
}
</style>
